<template>
  <div>
    <header class="grey-bg container-1560 header flex">
      <div class="d-flex">
        <b-link :to="{ name: 'form' }" class="logo">
          <img src="/logo.svg" alt="Mental Health Check" width="110" height="40" />
        </b-link>
        <div class="d-flex align-items-center ml-0 mx-sm-1">
          <div class="beta-version" v-b-tooltip.hover.right="'My Mental Health Check Beta!'">Beta</div>
        </div>
      </div>

      <div class="flex">
          <div class="justfy-content-center align-items-center pr-1 d-none d-md-flex">
            <!--<how-it-works size="sm" :show="true" />
            <small class="px-1">|</small>-->
            <about-us size="sm" :show="true" />
            <small class="px-1">|</small>

            <what-we-do size="sm" :show="true" />
            <small class="px-1">|</small> 

            <science size="sm" :show="true" />
            <invite-friends :show="false" />
            <small class="px-1">|</small> 
            <div class="p-0">
              <small><b-link :to="{name: 'faq'}">FAQ</b-link></small>
            </div>
            <small class="px-1">|</small> 
            <div class="p-0"><small><b-link @click="pricing()">Pricing</b-link></small></div>
            <pricing />
          </div>
          <div class="user-dropdown" @click="toggleDropdown" v-if="userData != undefined && userData.accessToken != undefined && userData.accessToken != ''">
              <a href="#" id="userDropbtn" class="button-base user-dropbtn d-flex align-items-center">
                <b-avatar :src="userData.avatar" size="40" />
                <div class="nav-username">
                  <div>{{ userData.name }}</div>
                  <div><small>{{ userData.username }}</small></div>
                </div>
              </a>
              <div id="userDropdownMenu" class="user-dropdown-menu" v-bind:class="{ active: isActive }">
                <b-link :to="{ name: 'my-hub' }">
                  <feather-icon size="16" icon="UserIcon" class="mr-50" />
                  <span>My Hub</span>
                </b-link>
                <!--<how-it-works class="d-md-none" :show="true" />-->
                
                <div class="p-0 d-md-none"><b-link :to="{name: 'faq'}">FAQ</b-link></div>
                <div class="p-0 d-md-none"><b-link @click="pricing()">Pricing</b-link></div>
                <b-link @click="logout">
                  <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                  <span>Logout</span>
                </b-link>
              </div>
          </div>

          <div class="login" v-else>
              <div class="d-none d-md-block">
                <b-link :to="{ name: 'login' }" class="btn btn-transparent-bg color-blue">
                  <span class="">Log In</span>
                </b-link>
                <b-link :to="{ name: 'register' }" class="btn btn-blue-bg ml-1">
                  <span class="">Sign Up</span>
                </b-link>
              </div>

              <div class="d-block d-md-none dropdown " :class="{show: showDropdownMenu}">
                <a class="btn btn-blue-bg" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <feather-icon size="20" icon="MenuIcon" class="dropdownMenuLinkIcon"/>
                  <div style="position: absolute; top:0; left:0; width:100%; height:100%;" id="dropdownMenuLink"  @click.prevent="toggleDropdownMenu"></div>
                </a>

                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                  <b-link :to="{ name: 'login' }" class="btn btn-transparent-bg color-blue">
                    <span class="">Log In</span>
                  </b-link>
                  <b-link :to="{ name: 'register' }" class="btn btn-blue-bg ml-1">
                    <span class="">Sign Up</span>
                  </b-link>
              
                  <div @click="aboutUs()" class="py-1 px-2 color-blue" style="cursor: pointer;">About Us</div>
                  <div @click="whatWeDo()" class="py-1 px-2 color-blue" style="cursor: pointer;">What We Do</div>
                  <div @click="ourApproach()" class="py-1 px-2 color-blue" style="cursor: pointer;">Our Approach</div>
                  <div class="py-1 px-2 color-blue" style="cursor: pointer;"><b-link :to="{ name: 'faq' }">FAQ</b-link></div>
                  <div @click="pricing()" class="py-1 px-2 color-blue" style="cursor: pointer;">Pricing</div>
                </div>
              </div>

          </div>
      </div>
    </header>
    <main class="grey-bg main-background questionnaire-main d-block">

        <div v-if="currentPage == 0  && (route == 'form' || route == '')" class="welcome-box">
          <div class="text-center">
            <h2 class="color-blue">Welcome to Mental Health Check</h2>
            <h5 class="mt-2">
              24/7 support to help you feel happier, confident and more in control everyday.
            </h5>
          </div>
        </div>

        <div class="questionnaire-placeholder mx-auto">  
          <router-view />
        </div>
    </main>

    <footer class="footer py-2">
        <div class="container-1560">
          <div class="row">

            <div class="col-12 col-md-3 mb-2">
              <div class="mt-1"><img src="/logo.svg" alt="Mental Health Check" width="110" height="40" /></div>
            </div>

            <div class="col-6 col-md-3">
              <p class="text-left mt-1 text-white">MENTAL HEALTH CHECK</p>
              <div class="text-left text-white">
                <div @click="aboutUs()" style="cursor: pointer;"><small>About Us</small></div>
                <div @click="whatWeDo()" style="cursor: pointer;"><small>What We Do</small></div>
                <div @click="ourApproach()" style="cursor: pointer;"><small>Our Approach</small></div>
                <div style="cursor: pointer;"><small><b-link :to="{ name: 'faq' }">FAQ</b-link></small></div>
                <div @click="pricing()" style="cursor: pointer;"><small>Pricing</small></div>
                <div><small><router-link to="contact-us">For Employers</router-link></small></div>
                <div><small><router-link to="contact-us">For Professionals</router-link></small></div>
              </div>
            </div>
            
            <div class="col-6">
              <div class="row">
                <div class="col-12 col-md-6">
                  <p class="text-left mt-1 text-white">JOIN OUR COMMUNITY</p>
                  <div class="text-left text-white">
                    <div><small><a href="https://www.instagram.com/mentalhealthchk/" target="_blank">Instagram</a></small></div>
                    <div><small><a href="https://www.facebook.com/mentalhealthchk/" target="_blank">Facebook</a></small></div>
                    <div><small><a href="">Blog</a></small></div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <p class="text-left mt-1 text-white">GET IN TOUCH</p>
                  <div class="text-left text-white">
                    <div><small><router-link to="contact-us">Contact Us</router-link></small></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mt-1">
            <p class="font-weight-normal text-white mb-0 mr-1">Copyright © 2021. MentalHealthCheck. All rights reserved.</p> |
            <p class="font-weight-normal text-white mb-0 mx-1"><b-link :to="{ name: 'privacy-policy' }">Privacy Policy</b-link></p> |
            <p class="font-weight-normal text-white mb-0 ml-1"><b-link :to="{ name: 'terms-and-conditions' }">Terms Of Use</b-link></p>
          </div>
        </div>
    </footer>
  </div>
</template>

<script>
import store from "@/store/index";
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import { BAvatar, BLink, VBTooltip } from "bootstrap-vue";
import EventBus from '../../main.js';

import HowItWorks from "../../components/modals/HowItWorks.vue"
import Science from "../../components/modals/Science.vue"
import Pricing from "../../components/modals/Pricing.vue"
import InviteFriends from "../../components/modals/InviteFriends.vue"
import DailyCheckIn from "../../components/modals/daily-check-in/DailyCheckIn.vue"
import WhatWeDo from "../../components/modals/WhatWeDo.vue"
import AboutUs from "../../components/modals/AboutUs.vue"

export default {
  components: {
    LayoutFull,
    BAvatar,
    BLink,
    WhatWeDo,
    AboutUs,

    HowItWorks,
    Science,
    Pricing,
    InviteFriends,
    DailyCheckIn
  },
  data() {
    return {
      isActive: false,
      route: "",
      showDropdownMenu: false
    };
  },
  computed: {
    userData() {
      return store.state.app.userData;
    },
    currentPage() {
      return store.state.formStoreModule.page;
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    toggleDropdown() {
      this.isActive = !this.isActive;
    },
    toggleDropdownMenu (e) {
      this.showDropdownMenu = !this.showDropdownMenu
    },
    // Logout
    logout() {      
      this.$http.get('/api/logout')
      .then(response => {        
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })
      .catch(error => {
        console.log(error)
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })

      // Remove user data and redirect to login page
      store.commit('app/removeUserData')
      localStorage.removeItem('userData');
      this.$router.push("/login")
    },
    pricing() {
      EventBus.$emit('showPricing', false)
    },
    aboutUs() {
      EventBus.$emit('openAboutUs', false)
    },
    whatWeDo() {
      EventBus.$emit('openWhatWeDo', false)
    },
    ourApproach() {
      EventBus.$emit('openScience', false)
    },
  },
  created(){
    this.route = this.$route.name;

    // Close dropdown on window click
    window.addEventListener('click', (e) => {
      if(e.target.id != 'userDropbtn') {
        this.isActive = false
      }
      if(e.target.id != 'dropdownMenuLink' && e.target.className != 'dropdownMenuLinkIcon') {
        this.showDropdownMenu = false
      }
    })
  },
  watch:{
    $route (to, from){
      this.route = to.name;
    }
} 
};
</script>

<style>
@import "../../assets/css/style.css";

#app {
  background-color: #f1f1f1 !important;
}
</style>

<style scoped>
  .beta-version {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    background-color: #245bf0;
    font-weight: 500;
    color: #fff;
  }
</style>